a {
  color: darkblue;
  transition: all .5s ease-in-out;
}

a:hover {
  color: darkviolet;
}

body {
  scroll-behavior: smooth;
  margin: 0;
}

button:focus {
  outline: none !important;
}

html::before {
  content: ' ';
  display: block;
  background-image: url("https://images.unsplash.com/photo-1597057845317-50e36468d7f8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2989&q=80");
  background-position: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -10;
}

h1 {
  transition: all .5s ease-in-out;
}

h1:hover {
  transform: scale(1.1);
}

ul {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

li {
  margin-bottom: 10px;
}

.alignCenter {
  text-align: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34;
  background: url("https://images.unsplash.com/photo-1597057845317-50e36468d7f8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2989&q=80") no-repeat fixed center; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bold {
  font-weight: bold;
}

.credentials {
  padding: .7rem;
  background: rgba(255, 255, 255, 0.4);
  border-top-right-radius: .5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  color: #aaa;
}

.credentials a {
  color: #aaa;
}

.credentials a:hover {
  color: darkblue;
}

.error {
  color: red;
  font-weight: bold;
}

.link {
  cursor: pointer;
}

@media (hover: none) and (pointer: coarse) {
  .link .textGray {
    color: darkblue;
  }
}

.mt-0-5rem {
  margin-top: .5rem;
}
.mt-1rem {
  margin-top: 1rem;
}
.mt-2rem {
  margin-top: 2rem;
}

.smallText {
  font-size: calc(9px + 1.8vmin);
}

.smallerText {
  font-size: calc(9px + 1.5vmin);
}

.smallestText {
  font-size: calc(8px + 1.3vmin);
}

.textGray {
  color: #888;
}

.smoothContainer {
  padding: .8rem 1rem;
  border-radius: .5rem;
  background: rgba(255,255,255,.6);
  color: #444;
}

.underline {
  text-decoration: underline;
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/