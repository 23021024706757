h1.title {
  margin-top: 20vh;
  margin-bottom: 3rem;
  color: #333;
  text-shadow: 0 0 0.5rem white;
}

h2 {
  margin-top: 2.5rem;
}

.acceptButton {
  width: fit-content;
  margin: 2rem auto;
  padding: 1rem 2rem;
  background: rgba(100,149,237,0.7);
  border-radius: 1.2rem;
  transition: all .2s ease-in-out;
}
.acceptButton:hover {
  background: rgba(57,113,205,1);
  transform: scale(1.1);
  box-shadow: 0 0 10px #444444;
  cursor: pointer;
}

.acceptButtonText {
  text-decoration: none;
  font-weight: bold;
  color: white;
}
.acceptButtonText:hover {
  color: white;
}

.declineButton {
  width: fit-content;
  margin: 1rem auto;
  padding: .2rem .8rem;
  font-size: 60%;
  color: #444444;
  border: solid 1px rgb(205,92,92);
  border-radius: .8rem;
  transition: all .2s ease-in-out;
}
.declineButton:hover {
  background: rgba(179,44,44,1);
  transform: scale(1.1);
  box-shadow: 0 0 5px #444444;
  cursor: pointer;
}
.declineButton:hover span {
  color: white !important;
}
.declineButton:hover span[role='img']::after {
  content: "😞";
}

.declineButtonText {
  text-decoration: none;
  color: #444444;
}

.invContainer {
  max-width: 920px;
  padding: 2rem;
  background: rgba(255,255,255,.7);
  color: #444;
  line-height: 140%;
  z-index: 1000;
}

.App-header {
  padding-bottom: 10vh;
}

@media (min-width: 620px) {
  .invContainer {
    margin: 0 8vw;
    border-radius: 1rem;
  }
}

.spaceBelow {
  margin-bottom: 2rem;
}

.mapContainer {
  margin: 1rem 10%;
}

.gmap {
  border: 0;
  border-radius: 1rem;
  width: 100%;
  min-height: 400px;
  transition: all .2s ease-in-out;
}

.gmap:hover {
  box-shadow: 0 0 1rem #444444;
}

@media (max-width: 620px) {
  .mapContainer {
    margin: 1rem .8rem;
  }
}

.infoContainer {
  text-align: left;
  max-width: 420px;
  margin: 0 auto;
}

.infoList {
  text-align: center;
}

@media (min-width: 800px) {
  .infoContainer {
    max-width: 760px;
  }
}

.arrival {
  text-align: center;
}

.scheduleFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: .5rem;
  border: 1px solid gray;
  border-radius: 1.5rem;
  margin: 2rem 1.5rem;
  transition: all .5s ease-in-out;
}
.scheduleFlex:hover {
  border: 1px solid darkblue;
  background: rgba(255,255,255,.6);
}

.scheduleFlex li {
  font-size: calc(9px + 1.8vmin);
}
.scheduleFlex li h4 {
  font-size: calc(10px + 2vmin);
  margin-top: 0;
}

.scheduleFlexItemL {
  padding: 1rem 1rem .5rem;
  flex: 1 1 40%;
}
.scheduleFlexItemR {
  padding: 1rem 1rem .5rem;
  flex: 1 1 50%;
}
.scheduleFlexItemR--both {
  text-align: left;
}
.scheduleFlexItemR--only {
  text-align: center;
  max-width: 520px;
  margin: 0 auto;
}
.scheduleFlexItemU {
  font-size: calc(9px + 1.8vmin);
  padding: .5rem;
  flex: 1 1 90%;
  text-align: center;
}

.bescheidGeben {
  text-decoration: underline;
  cursor: pointer;
}

.attention {
  background: rgba(255,255,255,.1);
  margin: 1.5rem 0;
  padding: .8rem 1rem;
  border: solid 1px #444444;
  border-radius: 1rem;
  transition: all .3s ease-in-out;
}

.attention:hover {
  border: solid 1px red;
  background: rgba(255,255,255,.6);
}

.credentials {
  display: none !important;
}