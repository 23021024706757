button {
  font-size: calc(9px + 1.8vmin);
  width: fit-content;
  transition: all .2s ease-in-out;
  border-style: none;
  background: none;
  color: #333333;
}

.inlineBtn {
  padding: .2rem .5rem;
  margin: 0 .3rem;
  border-radius: .5rem;
}
.inlineBtn--lg {
  padding: .5rem .8rem;
}

.outlineBtn {
  background: none;
  border: 1px solid rgb(57,113,205);
  border-radius: .5rem;
}
.outlineBtn:hover {
  background: rgb(57,113,205);
  color: white;
  cursor: pointer;
}
.outlineBtn:active {
  outline: none;
}

.oBtnActive {
  background: rgb(57,113,205);
  color: white;
  cursor: pointer;
  outline: none !important;
}
.oBtnActive:active {
  outline: none;
}

.slBtnActive {
  background: rgb(57,113,205);
  color: white;
  cursor: pointer;
  outline: none !important;
}
.slBtnActive:hover {
  background: none;
  border: 1px solid rgb(57,113,205);
  color: #333333;
}

.slCheckContainer {
  display: inline-flex;
  align-items: center;
  margin: 5px 10px;
}
.slCheckContainer span {
  transition: all .3s ease-in-out;
}
.slCheckbox {
  width: calc(10px + 1.5vmin);
  height: calc(10px + 1.5vmin);
}
.slCheckLabel:hover {
  cursor: pointer;
}

.backButton {
  padding: .5rem 1rem;
  color: #888888;
}
.backButton--red {
  border: 1px solid rgb(205,92,92);
}
.backButton--red:hover {
  background: rgba(179,44,44,1);
}

.resSubmit {
  font-size: calc(9px + 1.8vmin) !important;
  color: white;
  padding: 1rem 2rem;
  margin: 2rem auto;
  border-radius: 1.8rem;
}
.resAcceptBtn {
  background: rgba(100,149,237,0.7);
  border: 1px solid rgb(57,113,205);
}
.resAcceptBtn:hover {
  background: rgba(57,113,205,1);
  transform: scale(1.1);
  box-shadow: 0 0 10px #444444;
  cursor: pointer;
}

.resDeclineBtn {
  background: rgba(205,92,92,0.7);
  border: 1px solid darkred;
}
.resDeclineBtn:hover {
  background: rgba(179,44,44,1);
  transform: scale(1.1);
  box-shadow: 0 0 5px #444444;
  cursor: pointer;
}

.resContainer {
  background: rgba(255,255,255,0.6);
  margin: 1rem 1.5rem;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid gray;
}
.resContainer--accept {
  border: 1px solid rgb(57,113,205);
}
.resContainer--decline {
  border: 1px solid darkred;
}

.resTitle {
  margin-top: 1rem;
}
